import { Grow } from '@mui/material'
import ExternalLink from 'atoms/ExternalLink'
import { useEffect, useState } from 'react'

export interface ItemMenuExternalLinkIndicatorProps {}

export default function ItemMenuExternalLinkIndicator(props: ItemMenuExternalLinkIndicatorProps) {
  const [isCtrlOrCmdPressed, setIsCtrlOrCmdPressed] = useState(false)

  const handleIsCtrlOrCmdPressed = (e: KeyboardEvent) => {
    setIsCtrlOrCmdPressed(e.ctrlKey || e.metaKey)
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    if (!e.ctrlKey && !e.metaKey) {
      setIsCtrlOrCmdPressed(false)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleIsCtrlOrCmdPressed)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleIsCtrlOrCmdPressed)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return (
    <Grow in={isCtrlOrCmdPressed} timeout={300}>
      <div>{isCtrlOrCmdPressed ? <ExternalLink /> : null}</div>
    </Grow>
  )
}
