import qs from 'qs'
import { Layers, Mail, Person, Search } from '@mui/icons-material'
import {
  Box,
  Container,
  Divider,
  Grid,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Fade,
  Grow,
  Typography,
  useTheme,
  CircularProgress,
} from '@mui/material'
import { MENU_ITEMS } from 'app/Sidebar/Sidebar'
import useDebouncedValue from 'hooks/useDebouncedValue'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { getMessages } from 'services/kraken-core/messages/messages.service'
import MessageSearchItemResult from './MessageSearchItemResult'
import { searchTradingPartner } from 'services/kraken-core/trading_partner/tradingPartner.service'
import { TradingPartner } from 'common/types/kraken-core/TradingPartner'
import { ROUTES } from 'routing/routes'
import { TradingPartnerItemResult } from './TradingPartnerItemResult'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import { StackOfMessagesResultByReference } from './StackOfMessagesResult'
import { StackOfMessagesResultByPartner } from './StackOfMessagesResultForPartner'
import useCancelToken from 'hooks/useCancelToken'
import { useAppContext } from 'hooks/useUserContext/appUserContext'
import ItemMenuExternalLinkIndicator from './ItemMenuExternalLinkIndicator'

export interface GlobalSearchModalProps {
  open: boolean
  onClose?: () => void
}

enum SearchItemGroup {
  MENU_ITEM = 'Menu Items',
  MESSAGES = 'Messages',
  TRADING_PARTNERS = 'Trading Partners',
}

interface SearchItemResult {
  label: string
  icon?: React.ReactNode
  group: SearchItemGroup
  path?: string
  component?: React.ReactNode
  selectable: boolean
  requiresReload?: boolean
}

export default function GlobalSearchModal(props: GlobalSearchModalProps) {
  const theme = useTheme()
  const defaultItems: Array<SearchItemResult> = [
    ...MENU_ITEMS.map(item => ({
      label: item.label,
      icon: item.icon,
      group: SearchItemGroup.MENU_ITEM,
      path: item.path,
      selectable: true,
    })).filter(item => item.label !== 'Go to'),
  ]

  const appContext = useAppContext()
  const [filteredItems, setFilteredItems] = useState(defaultItems)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [loadingMenuItems, setLoadingMenuItems] = useState(false)
  const [loadingMessages, setLoadingMessages] = useState(false)
  const [loadingTradingPartners, setLoadingTradingPartners] = useState(false)

  const [searchTerm, setSearchTerm] = useState<string>()
  const debouncedSearchTerm = useDebouncedValue(searchTerm, 500)
  const history = useHistory()
  const searchInputRef = React.useRef<HTMLInputElement>(null)

  const [menuItemsResult, setMenuItemsResult] = useState<SearchItemResult[]>([])
  const [messagesResult, setMessagesResult] = useState<SearchItemResult[]>([])
  const [tradingPartnersResult, setTradingPartnersResult] = useState<SearchItemResult[]>([])
  const [loadingIndidcators, setLoadingIndicators] = useState<Array<SearchItemResult>>([])
  const [filteredPartners, setFilteredPartners] = useState<TradingPartner[]>([])

  const { getSource, cancelPending } = useCancelToken()

  const mergeResults = () => {
    setFilteredItems([
      ...menuItemsResult,
      ...messagesResult,
      ...tradingPartnersResult,
      ...loadingIndidcators,
    ])
  }

  const resetSearchResults = () => {
    setMenuItemsResult([])
    setMessagesResult([])
    setTradingPartnersResult([])
  }

  const goTo = (item: SearchItemResult, openNewTab = false) => {
    analytics.event({
      category: AnalyticsEvent.GlobalSearchGoTo,
      action: AnalyticsEventTrigger.keydown,
      label: AnalyticsEvent.GlobalSearchGoTo,
      value: item.label || item.path,
    })

    if (openNewTab) {
      window.open(item.path || '', '_blank')
      return
    }

    if (item.path) history.push(item.path)

    props.onClose?.()
    resetSearchResults()
    setSearchTerm(undefined)

    // If item requires page reload
    if (item.requiresReload) {
      window.location.href = item.path || ''
    }
  }

  const searchMessagesByReference = (searchQuery: string) => {
    setLoadingMessages(true)
    const source = getSource()
    getMessages(
      { page: 1, pageSize: 5, reference: searchQuery },
      { cancelToken: source.token }
    ).then(([error, result]) => {
      if (result && result.data && result.data.results) {
        const messages = result.data.results
        const messageItems = messages.map(message => ({
          label: '',
          group: SearchItemGroup.MESSAGES,
          icon: <Mail />,
          path: message.id ? `${ROUTES.MESSAGES}/${message.id}` : undefined,
          component: <MessageSearchItemResult message={message} />,
          selectable: true,
        }))

        const params = qs.stringify({ reference_code: searchQuery }, { skipNulls: true })
        const messageCountItem = {
          label: ``,
          group: SearchItemGroup.MESSAGES,
          icon: <Layers />,
          selectable: true,
          component: (
            <StackOfMessagesResultByReference
              count={result.data.count}
              referenceQuery={searchQuery}
            />
          ),
          path: `${ROUTES.MESSAGES}?${params}`,
          requiresReload: true,
        }

        setMessagesResult(prev => [...prev, ...messageItems])
        if (result.data.count > 5) {
          setMessagesResult(prev => [...prev, messageCountItem])
        }
      }
      setLoadingMessages(false)
    })
  }

  const searchMessagesByPartners = () => {
    setLoadingMessages(true)
    const source = getSource()
    filteredPartners.forEach(partner => {
      getMessages(
        { page: 1, pageSize: 1, partnerId: partner.id },
        { cancelToken: source.token }
      ).then(([error, result]) => {
        if (result && result.data && result.data.results) {
          if (result.data.count === 0) {
            setLoadingMessages(false)
            return
          }

          const params = qs.stringify(
            { partner: [{ ...partner, _type: 'string' }] },
            { skipNulls: true }
          )
          const messageItem = {
            label: '',
            group: SearchItemGroup.MESSAGES,
            icon: <Layers />,
            selectable: true,
            component: (
              <StackOfMessagesResultByPartner count={result.data.count} tradingPartner={partner} />
            ),
            path: `${ROUTES.MESSAGES}?${params}`,
            requiresReload: true,
          }
          setMessagesResult(prev => [...prev, messageItem])
        }
        setLoadingMessages(false)
      })
    })
  }

  const searchTradingPartners = (searchQuery: string) => {
    setLoadingTradingPartners(true)
    const source = getSource()
    searchTradingPartner(
      {
        filters: [
          {
            property: 'name',
            value: searchQuery,
          },
        ],
        page_size: 5,
      },
      { cancelToken: source.token }
    ).then(res => {
      if (res.response?.data?.results) {
        const tradingPartners: Array<TradingPartner> = res.response.data.results || []
        const tradingPartnerItems: any = tradingPartners
          .filter(tp => tp.name !== undefined || tp.name !== '')
          .map(tp => {
            return {
              group: SearchItemGroup.TRADING_PARTNERS,
              path: `${ROUTES.TRADING_PARTNER}/${tp.id}`,
              icon: <Person />,
              component: <TradingPartnerItemResult tradingPartner={tp} />,
              selectable: true,
            }
          })
        setFilteredPartners(tradingPartners)

        setTradingPartnersResult(prev => [...prev, ...(tradingPartnerItems as any)])
      }

      setLoadingTradingPartners(false)
    })
  }

  const searchMenuItems = (searchQuery: string) => {
    setLoadingMenuItems(true)
    const source = getSource()
    const searchResult = MENU_ITEMS.filter(item =>
      item.label.toLowerCase().includes(searchQuery.toLowerCase())
    ).map(item => ({
      label: item.label,
      icon: item.icon,
      group: SearchItemGroup.MENU_ITEM,
      path: item.path,
      selectable: true,
    }))
    setMenuItemsResult(prev => [...prev, ...searchResult])
    setLoadingMenuItems(false)
  }

  const search = () => {
    if (debouncedSearchTerm === '' || debouncedSearchTerm === undefined) {
      setMenuItemsResult(defaultItems)
      return
    }

    analytics.event({
      category: AnalyticsEvent.GlobalSearchRunSearch,
      action: AnalyticsEventTrigger.keydown,
      label: AnalyticsEvent.GlobalSearchRunSearch,
    })

    setSelectedIndex(0)

    // Cancel previous search requests
    cancelPending()

    searchMenuItems(debouncedSearchTerm)
    searchMessagesByReference(debouncedSearchTerm)
    searchTradingPartners(debouncedSearchTerm)
  }

  useEffect(
    function setupModalKeyboardActions() {
      const handleKeyDown = (event: KeyboardEvent) => {
        const isCtrlOrCmd = event.ctrlKey || event.metaKey
        if (isCtrlOrCmd && event.key === 'k') {
          event.preventDefault()
        }

        // If modal is not open, do nothing
        if (props.open === false) return

        const selected = filteredItems[selectedIndex]
        if (!selected) return

        switch (event.key) {
          case 'Enter':
            if (selected.path) {
              if (isCtrlOrCmd) {
                goTo(selected, true)
              } else {
                goTo(selected)
              }
            }
            break
          case 'ArrowUp':
            setSelectedIndex(prevIndex =>
              prevIndex > 0 ? prevIndex - 1 : filteredItems.length - 1
            )
            break
          case 'ArrowDown':
            setSelectedIndex(prevIndex =>
              prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0
            )
            break
        }
      }
      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    },
    [filteredItems, selectedIndex]
  )

  useEffect(() => {
    resetSearchResults()
    search()
  }, [debouncedSearchTerm])

  useEffect(() => {
    const updateLoadingIndicators = (group: SearchItemGroup, isLoading: boolean) => {
      const isIndicatorAlreadyOnTheList = filteredItems.find(
        item => item.label === 'Searching...' && item.group === group
      )
      if (isLoading && !isIndicatorAlreadyOnTheList) {
        setLoadingIndicators(prev => [
          ...prev,
          {
            label: 'Searching...',
            group: group,
            icon: <CircularProgress size={20} />,
            selectable: false,
          },
        ])
      } else if (!isLoading && isIndicatorAlreadyOnTheList) {
        // remove loading indicator
        setLoadingIndicators(prev => [
          ...prev.filter(item => item.label !== 'Searching...' || item.group !== group),
        ])
      }
    }

    updateLoadingIndicators(SearchItemGroup.MESSAGES, loadingMessages)
    updateLoadingIndicators(SearchItemGroup.TRADING_PARTNERS, loadingTradingPartners)
    updateLoadingIndicators(SearchItemGroup.MENU_ITEM, loadingMenuItems)
  }, [loadingMessages, loadingTradingPartners, loadingMenuItems])

  useEffect(() => {
    mergeResults()
  }, [menuItemsResult, messagesResult, tradingPartnersResult, loadingIndidcators])

  useEffect(() => {
    if (props.open) {
      analytics.event({
        category: AnalyticsEvent.GlobalSearchOpen,
        action: AnalyticsEventTrigger.click,
        label: AnalyticsEvent.GlobalSearchOpen,
      })
      setSearchTerm(undefined)
      resetSearchResults()
      search()
      searchInputRef.current?.focus()
    }
  }, [props.open])

  useEffect(() => {
    const listItem = document.querySelector(`#search-item-${selectedIndex}`)
    if (listItem) {
      listItem.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
    }
  }, [selectedIndex])

  useEffect(() => {
    if (props.open) searchMessagesByPartners()
  }, [filteredPartners])

  const itemResultRenderedGroups: any = {}

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      disableEnforceFocus
      disableAutoFocus
      // closeAfterTransition
      BackdropProps={{
        onClick: props.onClose,
      }}
    >
      <Fade
        in={props.open}
        easing={{
          enter: 'ease-in',
          exit: 'ease-out',
        }}
      >
        <Container
          onClick={props.onClose}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '20vh',
            paddingBottom: '5vh',
            // height: '100%',
            width: '100%',
            overflow: 'hidden',
          }}
        >
          <Paper
            onClick={e => e.stopPropagation()} // Prevent click event from propagating to the backdrop
            sx={{
              borderRadius: 5,
              maxHeight: '65vh', // Changed to be dynamic based on the screen height
              width: '70%',
              boxShadow: 20,
            }}
          >
            <Box sx={{ paddingBottom: 2 }}>
              <Stack>
                <Grid
                  p={2}
                  container
                  spacing={1}
                  alignItems={'center'}
                  sx={{ width: '100%' }}
                  justifyContent={'space-between'}
                >
                  <Grid item md={1}>
                    <Search fontSize="large" />
                  </Grid>
                  <Grid item md={11}>
                    <InputBase
                      placeholder="Search..."
                      fullWidth
                      onChange={e => setSearchTerm(e.target.value)}
                      autoFocus
                      inputRef={searchInputRef}
                      onKeyDown={e => {
                        if (e.key === 'Enter') {
                          e.preventDefault()
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Stack sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
                  <List disablePadding>
                    {filteredItems.map((item, index) => {
                      const components: any = []
                      if (!itemResultRenderedGroups.hasOwnProperty(item.group)) {
                        itemResultRenderedGroups[item.group] = true
                        components.push(
                          <Grow in={true} timeout={300} key={`${index}_group`}>
                            <ListItem
                              key={item.group}
                              dense
                              sx={{
                                backgroundColor: theme.palette.grey[200],
                                paddingLeft: 2,
                                paddingRight: 2,
                              }}
                            >
                              <ListItemText
                                primary={
                                  <Typography variant="caption" fontWeight={'bold'}>
                                    {item.group}
                                  </Typography>
                                }
                              />
                            </ListItem>
                          </Grow>
                        )
                      }
                      const isSelected = index === selectedIndex
                      components.push(
                        <Grow in={true} timeout={300} key={`${index}_item`}>
                          {item.selectable ? (
                            <ListItem
                              id={`search-item-${index}`}
                              disablePadding
                              selected={index === selectedIndex}
                              dense
                            >
                              <ListItemButton
                                onClick={e => {
                                  if (item.path != undefined) {
                                    goTo(item)
                                    console.log("Can't go to this item: ", item)
                                  }
                                  e.preventDefault()
                                }}
                              >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                                {item.component ? item.component : null}
                                {isSelected ? <ItemMenuExternalLinkIndicator /> : null}
                              </ListItemButton>
                            </ListItem>
                          ) : (
                            <ListItem id={`search-item-${index}-info`} disablePadding dense>
                              <ListItemButton>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                                {item.component ? item.component : null}
                              </ListItemButton>
                            </ListItem>
                          )}
                        </Grow>
                      )

                      return components
                    })}
                    {filteredItems.length === 0 && (
                      <ListItem>
                        <ListItemText
                          primary={<Typography variant="body2">No results found</Typography>}
                        />
                      </ListItem>
                    )}
                  </List>
                </Stack>
              </Stack>
            </Box>
          </Paper>
        </Container>
      </Fade>
    </Modal>
  )
}
