import { Banner } from '@loadsmart/loadsmart-ui'
import React from 'react'

interface WarningPanelProps {
  title?: string
  description?: string
  dismissible?: boolean
}

const DEFAULT_WARNING_TITLE = 'Warning'
const DEFAULT_WARNING_DESCRIPTION = `
  This component is managed via Infrastructure as Code (IaC) and 
  MUST NOT be manually edited or deleted. 
  To make changes, update the IaC configuration.
`

const IaCComponentReferenceWarning: React.FC<WarningPanelProps> = ({
  title = DEFAULT_WARNING_TITLE,
  description = DEFAULT_WARNING_DESCRIPTION,
  dismissible = false,
}) => {
  return (
    <Banner
      variant="warning"
      title={title}
      scale="large"
      description={description}
      dismissible={dismissible}
    />
  )
}

export default IaCComponentReferenceWarning
